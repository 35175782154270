import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Link from "@src/components/core-link"
const StyledNav = styled.div`
  position: relative;
  display: flex;
  justify-content: space-around;
`
const StyledLink = styled(Link)``
const NavInPage = ({ links, className }) => {
  return (
    <StyledNav className={className}>
      {links &&
        links.length &&
        links.map(({ title, ...props }) => (
          <StyledLink key={title} {...props}>
            {title}
          </StyledLink>
        ))}
    </StyledNav>
  )
}
NavInPage.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      scroll: PropTypes.shape({
        to: PropTypes.string,
      }),
    })
  ),
  className: PropTypes.string,
}
export default NavInPage
