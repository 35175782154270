// source: https://stackoverflow.com/a/2970667/1798697
export const camelcase = value =>
  value
    .toLowerCase()
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (ltr, idx) =>
      idx === 0 ? ltr.toLowerCase() : ltr.toUpperCase()
    )
    .replace(/\s+/g, "")

// source: https://stackoverflow.com/a/43376967/1798697
export const ucwords = value =>
  value
    .toLowerCase()
    .split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ")

export const cropWords = (value, wordCount) => {
  if (!value) {
    return ""
  }
  const words = value.split(" ", wordCount + 1)
  if (words.length > wordCount) {
    let result = words.slice(0, wordCount).join(" ")
    let checkChar = result.search(/[.!]/g)
    if (checkChar !== -1) {
      return result.substr(0, checkChar + 1)
    }
    checkChar = result.search(/[,]/g)
    if (checkChar !== -1) {
      return result.substr(0, checkChar)
    }
    return result + "..."
  }
  return value
}
