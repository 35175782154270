export const ICON_BAR_DEFAULT = [
  {
    icon: "route",
    text: "Detailed Route Guides",
  },
  {
    icon: "pin-round",
    text: "Real GPS Coordinates",
  },
  {
    icon: "map",
    text: "Reliable Trail Reports",
  },
  {
    icon: "lightbulb",
    text: "Experienced-Based Travel Tips",
  },
  {
    icon: "map-pin-location",
    text: "GPS App",
  },
  {
    icon: "info",
    text: "Extensive Travel Guides",
  },
]
