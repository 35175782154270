import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Link from "@src/components/core-link"
import Icon from "@src/components/core-icon"
const Ratings = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (${props => props.theme.screen.small.min}) {
    flex-direction: row;
    justify-content: center;
  }
`
const StyledLink = styled(Link)`
  font-size: 14px;
  line-height: 17px;
  & .icon {
    font-size: 16px;
    margin-left: 2px;
  }
`
const Rating = styled.div`
  flex: 0 0 auto;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  background-color: ${props => props.theme.lightBlue};
  color: ${props => props.theme.darkBlue};

  margin: 5px 0;
  padding: 10px;
  border-radius: 4px;

  @media only screen and (${props => props.theme.screen.small.min}) {
    flex: 0 1 247px;
    margin: 5px;
    padding: 16px 10px;
    border-radius: 8px;
  }
`
const RatingHeader = styled.h3`
  flex: 0 0 auto;
  padding-bottom: 10px;
  @media only screen and (${props => props.theme.screen.small.min}) {
    padding-bottom: 6px;
  }
`
const RatingContent = styled.div`
  flex: 1 1 auto;
`
const RatingActions = styled.div`
  flex: 0 0 auto;
  padding-top: 20px;
  text-align: right;
  @media only screen and (${props => props.theme.screen.small.min}) {
    text-align: left;
  }
`
const DifficultyRatings = ({ ratings }) => {
  return (
    <Ratings>
      {ratings &&
        ratings.map(rating => (
          <Rating key={rating.title}>
            <RatingHeader>{rating.title}</RatingHeader>
            <RatingContent>
              <p>{rating.description}</p>
            </RatingContent>
            {rating.link && (
              <RatingActions>
                <StyledLink to={rating.link} variant="blue">
                  See {rating.title} Trails
                  <Icon glyph="circle-chevron-right" />
                </StyledLink>
              </RatingActions>
            )}
          </Rating>
        ))}
    </Ratings>
  )
}
DifficultyRatings.propTypes = {
  ratings: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      link: PropTypes.string,
    })
  ),
}
export default DifficultyRatings
