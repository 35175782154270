import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import FeaturedTile from "@src/components/core-featured-tile"
import Button from "@src/components/core-button"
import { ImagePropTypes } from "@src/components/core-image"
const FeaturedTiles = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -3px;
  & > * {
    margin: 5px;
  }

  @media only screen and (${props => props.theme.screen.tablet.min}) {
    width: 375px;
  }

  @media only screen and (${props => props.theme.screen.small.min}) {
    width: 694px;
    margin: -5px;
    & > * {
      margin: 5px;
    }
  }

  @media only screen and (${props => props.theme.screen.desktop.min}) {
    width: auto;
  }
`
const FeaturedContentTiles = ({
  title,
  content,
  actions,
  viewMore,
  variant,
}) => {
  const actionList = []
  if (viewMore) {
    actionList.push(
      <Button
        key={`view-more-${viewMore}`}
        to={viewMore}
        aria-label="view more contents"
      >
        View more
      </Button>
    )
  }
  if (actions && actions.length) {
    actionList.push(...actions)
  }
  const ContentTiles =
    content &&
    content.map(tile => {
      return (
        tile && (
          <FeaturedTile
            content={tile}
            variant={variant}
            key={tile.id || tile.link || tile.title}
            {...tile}
          />
        )
      )
    })
  return (
    <>
      {title && <h2>{title}</h2>}
      <FeaturedTiles>{ContentTiles}</FeaturedTiles>
      {actionList.length ? (
        <div className="actions">{actionList}</div>
      ) : undefined}
    </>
  )
}
FeaturedContentTiles.propTypes = {
  title: PropTypes.string,
  variant: PropTypes.oneOf([
    "text-overlay",
    "text-ribbon",
    "text-overlay-small",
  ]),
  content: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      link: PropTypes.string,
      image: PropTypes.shape({ ...ImagePropTypes }),
    })
  ),
  actions: PropTypes.arrayOf(PropTypes.node),
  viewMore: PropTypes.string,
}
export default FeaturedContentTiles
