import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import GTM from "@src/services/gtm"
import PrimaryLayout from "@src/layouts/primary"
import PageHelmet, { PageSeoPropTypes } from "@src/components/page-helmet"
import { normalizeTitle, generateMeta } from "@src/utils/page-data"
import HeaderSimple from "@src/components/page-header-simple"
import ResponsiveSection from "@src/components/container-responsive-section"
import NavInPage from "@src/components/nav-content-in-page"
import IconContentBar from "@src/components/core-icon-bar"
import DifficultyRatings from "@src/components/content-difficulty-ratings"
import BrowseRouteGuides from "@src/components/cta-browse-route-guides"
import PostTiles from "@src/components/content-post-tiles"
import { PostTileQueryPropTypes } from "@src/components/content-post-tile"
import RegionTiles from "@src/components/content-region-tiles"
import { ImagePropTypes } from "@src/components/core-image"
import HtmlContent from "@src/components/core-value-html"
import { camelcase, ucwords } from "@src/utils/strings"
import { orderByField } from "@src/utils/sorting"
import { ICON_BAR_DEFAULT } from "@src/utils/constants/icon-bar-default"
import { DIFFICULTY_RATINGS } from "@src/utils/constants/difficulty-ratings"
import { BASE_PATH_BLOG, SITE_DOMAIN } from "@src/utils/constants"
import generateBreadcrumbList from "@src/utils/structured-data/generateBreadcrumbList"
import ReadMoreText from "@src/components/core-value-read-more-text"

const PageTemplate = ({ data, pageContext }) => {
  const {
    page: {
      activityId,
      uri: activityUri,
      name,
      nameSport,
      description,
      images: { feature: featureImage },
      seo: pageSeo,
    },
    posts: { nodes: posts },
  } = data
  const {
    topRegions: { nodes: topRegions },
    regions: { nodes: regions },
    activityTypePostIds,
  } = pageContext
  posts.sort(orderByField("graphId", activityTypePostIds))

  const sections = {
    description: { title: ucwords(nameSport), key: "description" },
  }
  if (topRegions) {
    sections.bestRegions = {
      title: `Best Regions for ${ucwords(nameSport)}`,
      key: "best-regions",
    }
  }
  if (regions) {
    sections.aroundTheWorld = {
      title: `${name} Around the World`,
      key: `activity-global`,
    }
  }
  sections.difficulty = {
    title: `${ucwords(nameSport)} Difficulty Rating`,
    key: `difficulty-rating`,
  }
  sections.relatedStories = {
    title: `Related Stories`,
    key: `related-stories`,
  }
  GTM.dataLayerPushPageInfo({
    template: "activity-type",
  })
  const rawBreadcrumbStructuredData = [
    { name, url: `${SITE_DOMAIN}${activityUri}`, position: 1 },
  ]
  const showReadMore = description && description.split("<p>").length > 2
  return (
    <PrimaryLayout>
      <PageHelmet
        title={normalizeTitle(pageSeo.title)}
        meta={generateMeta(activityUri, {
          ...pageSeo,
          metaRobotsNoindex: "index",
        })}
        structuredData={generateBreadcrumbList(rawBreadcrumbStructuredData)}
      />
      <HeaderSimple image={featureImage}>
        <h1>Best {name}</h1>
      </HeaderSimple>
      <ResponsiveSection visible="small">
        <NavInPage
          links={Object.values(sections)?.map(({ key, title }, index) => ({
            title,
            scroll: { to: camelcase(key) },
            variant: "fancy",
            active: index === 0,
          }))}
        />
      </ResponsiveSection>
      <ResponsiveSection id={camelcase(sections.description.key)}>
        <h2>{sections.description.title}</h2>
        {showReadMore ? (
          <ReadMoreText>
            <HtmlContent html={description} />
          </ReadMoreText>
        ) : (
          <HtmlContent html={description} />
        )}
      </ResponsiveSection>
      {topRegions ? (
        <ResponsiveSection
          id={camelcase(sections.bestRegions.key)}
          variant="tiles"
        >
          <h2>{sections.bestRegions.title}</h2>
          <RegionTiles
            content={topRegions.map(topRegion => ({
              title: topRegion.title,
              link: topRegion.uri,
              image: topRegion.images?.featureTile,
            }))}
          />
        </ResponsiveSection>
      ) : null}
      {regions && regions.length ? (
        <ResponsiveSection id={camelcase(sections.aroundTheWorld.key)}>
          <h2>{sections.aroundTheWorld.title}</h2>
          <BrowseRouteGuides
            regions={regions}
            activityType={{ id: activityId }}
          />
        </ResponsiveSection>
      ) : null}
      <ResponsiveSection id={camelcase(sections.difficulty.key)}>
        <h2>{sections.difficulty.title}</h2>
        <DifficultyRatings ratings={DIFFICULTY_RATINGS} />
      </ResponsiveSection>
      <ResponsiveSection
        id={camelcase(sections.relatedStories.key)}
        variant="tiles"
      >
        <h2>{sections.relatedStories.title}</h2>
        <PostTiles content={posts} viewMore={BASE_PATH_BLOG} />
      </ResponsiveSection>
      <ResponsiveSection variant="blue" visible="small">
        <IconContentBar icons={ICON_BAR_DEFAULT} />
      </ResponsiveSection>
    </PrimaryLayout>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      activityId: PropTypes.number,
      slug: PropTypes.string,
      uri: PropTypes.string,
      name: PropTypes.string,
      nameSport: PropTypes.string,
      description: PropTypes.string,
      images: PropTypes.shape({
        feature: PropTypes.shape({ ...ImagePropTypes }),
      }),
      seo: PropTypes.shape({ ...PageSeoPropTypes }),
    }),
    posts: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          graphId: PropTypes.string,
          ...PostTileQueryPropTypes,
        })
      ),
    }),
  }),
  pageContext: PropTypes.shape({
    topRegions: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          uri: PropTypes.string,
          images: PropTypes.shape({
            featureTile: PropTypes.shape({ ...ImagePropTypes }),
          }),
        })
      ),
    }),
    regions: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.object),
    }),
    activityTypePostIds: PropTypes.arrayOf(PropTypes.string),
  }),
}

export default PageTemplate

export const query = graphql`
  query ($id: String!, $activityTypePostIds: [String]) {
    page: wpActivityType(id: { eq: $id }) {
      activityId: databaseId
      slug
      uri
      name
      nameSport
      description: descriptionRendered
      images {
        feature {
          ...WpImageSizesQuery
        }
      }
      seo {
        ...WpTermSeoQuery
      }
    }
    posts: allWpPost(filter: { id: { in: $activityTypePostIds } }) {
      nodes {
        graphId: id
        ...WpPostDetailsQuery
      }
    }
  }
`
