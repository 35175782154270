export const DIFFICULTY_RATINGS = [
  {
    value: 1,
    title: "Very Easy",
    description:
      "At this level, experience is not necessary and anyone in good health who can manage walking long.",
  },
  {
    value: 2,
    title: "Easy",
    description:
      "At this level, experience is not necessary and anyone in good health who can manage walking long.",
  },
  {
    value: 3,
    title: "Moderate",
    description:
      "Moderate treks will reach slightly higher altitudes with more difficult days included. ",
  },
  {
    value: 4,
    title: "Hard",
    description:
      "An excellent level of physical and mental stamina will be required. ",
  },
  {
    value: 5,
    title: "Very Hard",
    description:
      "An excellent level of physical and mental stamina will be required. ",
  },
]
